export const user = state => state.user;
export const userResetPassword = state => state.userResetPassword;
export const userRole = state => state.userRole;
export const isModerator = state => state.isModerator;
export const moderatorLink = state => state.moderatorLink;
export const isGoogleMapsInitialized = state => state.isGoogleMapsInitialized;
export const companyAbilities = state => state.companyAbilities;
export const userProfile = state => state.userProfile;
export const content = state => state.content;
export const authorizationModalSettings = state => state.authorizationModalSettings;
export const referralCode = state => state.referralCode;
export const pdcCode = state => state.pdcCode;
export const currentCompanyId = state => state.currentCompanyId;
export const currentBrandId = state => state.currentBrandId;
export const companies = state => state.companies;
export const company = state => state.company;
export const administratedCompanies = state => state.administratedCompanies;
export const isPlaceAdministrator = state => state.isPlaceAdministrator;
export const isVKMiniApp = state => state.isVKMiniApp;
export const isShowBanner = state => state.isShowBanner;
export const VKMiniAppPlatform = state => state.VKMiniAppPlatform;
export const headerRouteName = state => state.headerRouteName;
export const geoposition = state => state.geoposition;
export const isGeopositionAllowed = state => state.isGeopositionAllowed;
export const isRouteLoading = state => state.isRouteLoading;
export const placesCount = state => state.placesCount;
export const errorText = state => state.errorText;
export const VKUserInfo = state => state.VKUserInfo;
export const personStock = state => state.personStock;
export const enterFrom = state => state.enterFrom;
export const doneSteps = state => state.doneSteps;
export const vkLoginModalSettings = state => state.vkLoginModalSettings;
export const backpaths = state => state.backpaths;
export const unreadNotifications = state => state.unreadNotifications;
export const metaData = state => state.metaData;
/**
 * @param state
 * @returns { HeaderSettingsOptions }
 */
export const settings = state => state.settings;

export const userDiscountCards = state => state.userDiscountCards;
export const markets = state => state.markets;
export const token = state => state.user.token;
export const loyaltyProgramCompany = state => state.loyaltyProgramCompany;
export const loyaltyProgramUser = state => state.loyaltyProgramUser;
export const sidebarOpen = state => state.sidebarOpen;
export const getBenefittySteps = state => state.benefittySteps;
export const mainInfoIsLoad = state => state.mainInfoIsLoad;
export const refreshTokenException = state => state.refreshTokenException;
