<template>
    <div class="geolocation-modal">
        <div class="geolocation-modal__mask"></div>

        <div class="geolocation-modal__component">
            <div class="geolocation-modal__content">
                <div class="geolocation-modal__header">
                    <div class="geolocation-modal__robot"></div>

                    <div class="geolocation-modal__header-info">
                        <h4>Умный помощник</h4>

                        <div>
                            Наш искусственный интеллект рекомендует вам воспользоваться:
                        </div>
                    </div>
                </div>

                <div class="geolocation-modal__body">
                    При работе с нашим приложением необходимо предоставить доступ к геолокации, чтобы корректно использовать все функции сервиса BenefittY и своевременно получать спецпредложения рядом с вами 
                </div>
            </div>

            <div class="geolocation-modal__controls">
                <button class="geolocation-modal__button" @click="close">
                    Понятно
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import lazyImages from '@/utils/lazyImages';
import bridge from '@vkontakte/vk-bridge';

export default {
    name: 'GeolocationModal',

    data() {
        return {
            lazyImages,
        };
    },

    async mounted() {
        await this.setGeolocationAccessDate();
    },

    methods: {
        async close() {
            await this.setGeolocationReadFlag();

            this.$emit('resolve');
            this.$emit('close');
        },

        setGeolocationAccessDate() {
            return bridge.send('VKWebAppStorageSet', {
                key: 'geolocation_access_date',
                value: String(new Date().getTime()),
            })
                .then(data => { 
                })
                .catch(error => {
                });
        },

        setGeolocationReadFlag() {
            return bridge.send('VKWebAppStorageSet', {
                key: 'geolocation_read_flag',
                value: String(new Date().getTime()),
            })
                .then(data => { 
                })
                .catch(error => {
                });
        },
    },
};
</script>

<style lang="scss" scoped>
    .geolocation-modal {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        width: 100vw;
        height: 100vh;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 99999;

        &__mask {
            position: fixed;
            background: rgba(#000, .5);
            width: 100vw;
            height: 100vh;
        }

        &__component {
            position: absolute;
            width: calc(100% - 20px);
            border-radius: 10px;
            background: $white;
        }

        &__content {
            padding: 16px;
        }

        &__controls {
            padding: 10px 16px;
            border-radius: 0 0 10px 10px;
            background: $white;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, .15);
        }

        &__button {
            width: 100%;
            height: 30px;
            border-radius: 10px;
            background: linear-gradient(90deg, #1fd0c4 0%, #1eabc4 100%);
            color: $white;
            border: none;
            font-size: 12px;
            font-weight: 400;
            letter-spacing: -.12px;
        }

        &__robot {
            width: 44px;
            height: 71px;
            background-position: center;
            background-size: cover;
            background-image: url('../../assets/images/robot.png');
        }

        &__body {
            margin-bottom: 10px;
            color: #475569;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
        }

        &__header {
            display: flex;
            column-gap: 12px;
            align-items: center;
            margin-bottom: 24px;

            &-info {

                h4 {
                    color: #282929;
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 26px;
                }

                div {
                    color: #707070;
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: -.14px;
                }
            }
        }
    }
</style>
